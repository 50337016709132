import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingComponent } from '../../heading/heading.component';

@Component({
  selector: 'app-modal-new-title',
  templateUrl: './modal-new-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadingComponent],
})
export class ModalNewTitleComponent {}
